<template>
  <h1 class="text-center text-danger">
    Reports
  </h1>
</template>

<script>

export default {
  name: 'Orders',
  created() {
    this.$emit('updateMenu', 'settings-reports-list')
  },
}
</script>

<style scoped>

</style>
